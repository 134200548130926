




















































import Vue from "vue"

import CmsSectionBigHeader from "@/components/CmsSectionBigHeader.vue"
import HeroBGImage from "@/components/HeroBGImage.vue"
import LunchSection from "@/components/LunchSection.vue"
import {
  newEmailLink,
  newTelephoneLink
} from "@/model/Link"
import ParallaxFixed from "@/components/ParallaxFixed.vue"
import SectionBigHeader from "@/components/SectionBigHeader.vue"

export default Vue.extend({
  components: {
    CmsSectionBigHeader,
    HeroBGImage,
    LunchSection,
    ParallaxFixed,
    SectionBigHeader
  },

  data() {
    return {
      links: {
        gutscheine: [
          newTelephoneLink(),
          newEmailLink()
        ],
        location: [
          { text: "Location", to: { name: "Location" } },
          { text: "Kontakt", to: { name: "Kontakt" } }
        ],
        kueche: [
          { text: "Speisekarte", to: { name: "Speisekarte" } },
          { text: "Mittagstisch", to: { name: "Home", hash: "anchor-lunch" } },
          { text: "Küche", to: { name: "Kueche" } }
        ]
      }
    }
  }
})
