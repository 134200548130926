





















import Vue, { PropType } from "vue"

import { Link } from "@/model/Link"
import SectionBigHeaderContent from "@/components/SectionBigHeaderContent.vue"

export default Vue.extend({
  components: {
    SectionBigHeaderContent
  },

  props: {
    links: {
      type: Array as PropType<Link[]>,
      default: () => []
    },
    inverted: {
      type: Boolean,
      default: false
    }
  }
})
