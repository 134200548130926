import {
  sortBy
} from "lodash-es"

import { unreachable } from "@/util/assert"

export enum LunchDishCategory {
  // see README for reason of lowercase
  Starter = "starter",
  MainDish = "main_dish",
  Dessert = "dessert"
}

/* eslint-disable camelcase */
export interface LunchDish {
  id: string
  category: LunchDishCategory
  line_1: string
  line_2?: string | null
  sort?: number | null
}
/* eslint-enable camelcase */

/* groups LunchDishes by category */
export type LunchDishesCategoryDictionary = {
  [K in LunchDishCategory]?: LunchDish[]
}

/* grouped by category, dishes in category sorted */
export function orderByCategory(ds: LunchDish[]): LunchDishesCategoryDictionary {
  // Group dishes - not using lodash' groupedBy as it messes up typings.
  const grouped: LunchDishesCategoryDictionary = {}
  for (const d of ds) {
    if (!grouped[d.category]) {
      grouped[d.category] = []
    }
    grouped[d.category]?.push(d)
  }

  // Sort dishes within groups.
  for (const c in grouped) {
    const categ = c as LunchDishCategory
    const arr = grouped[categ]
    grouped[categ] = sortBy(arr, d => d.sort)
  }

  return grouped
}

export function displayCategory(c: LunchDish["category"]): string {
  switch (c) {
  case LunchDishCategory.Starter:
    return "Vorspeise"
  case LunchDishCategory.MainDish:
    return "Hauptgang"
  case LunchDishCategory.Dessert:
    return "Dessert"
  default:
    unreachable(c)
  }
}
