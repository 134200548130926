


















import Vue, { PropType } from "vue"

import { LunchDish } from "@/model/LunchDish"
import LunchDishComponent from "@/components/LunchDish.vue"

export default Vue.extend({
  components: {
    LunchDishComponent
  },

  props: {
    dishes: {
      type: Array as PropType<LunchDish[]>,
      default: () => []
    }
  }
})
