

















import Vue, { PropType } from "vue"

import BaseLink from "@/components/BaseLink.vue"
import { Link } from "@/model/Link"

export default Vue.extend({
  components: {
    BaseLink
  },

  props: {
    links: {
      type: Array as PropType<Link[]>,
      default: () => []
    },
    /* Will be used as css font-size. */
    fontSize: {
      type: String,
      default: undefined
    },
    textDecoration: {
      type: Boolean,
      default: true
    }
  }
})
