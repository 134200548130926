






















import Vue from "vue"

import { navLinks } from "@/model/Link"

export default Vue.extend({
  props: {
    justifyContentEnd: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      navLinks
    }
  },

  computed: {
    ulStyleObject(): Record<string, string | boolean> {
      return {
        "ul--justify-end": this.justifyContentEnd
      }
    }
  }
})
