


























import Vue from "vue"

import { navLinks } from "@/model/Link"
import PopSide from "@/components/PopSide.vue"

export default Vue.extend({
  components: {
    PopSide
  },

  data() {
    return {
      navLinks
    }
  }
})
