



















import Vue from "vue"

import LogoPadded from "@/components/LogoPadded.vue"
import NavLinks from "@/components/NavLinks.vue"

export default Vue.extend({
  components: {
    LogoPadded,
    NavLinks
  }
})
