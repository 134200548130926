














import Vue, { PropType } from "vue"

import { Link } from "@/model/Link"
import LinkList from "@/components/LinkList.vue"

export default Vue.extend({
  components: {
    LinkList
  },

  props: {
    links: {
      type: Array as PropType<Link[]>,
      default: () => []
    }
  }
})
