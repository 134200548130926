
















import Vue, { PropType } from "vue"

import { arrayID } from "@/util/id"
import {
  displayCategory,
  LunchDish,
  LunchDishCategory,
  LunchDishesCategoryDictionary,
  orderByCategory
} from "@/model/LunchDish"
import LunchCategory from "@/components/LunchCategory.vue"

export default Vue.extend({
  components: {
    LunchCategory
  },

  props: {
    dishesUnordered: {
      type: Array as PropType<LunchDish[]>,
      default: () => []
    }
  },

  data() {
    return {
      arrayID,
      displayCategory
    }
  },

  computed: {
    /* grouped and sorted */
    dishes(): LunchDishesCategoryDictionary {
      return orderByCategory(this.dishesUnordered)
    },

    /* contains no empty elements */
    dishesDisplay(): LunchDish[][] {
      // order matters
      const all = [
        this.dishes[LunchDishCategory.Starter] ?? [],
        this.dishes[LunchDishCategory.MainDish] ?? [],
        this.dishes[LunchDishCategory.Dessert] ?? []
      ]
      return all.filter(ds => ds.length > 0)
    }
  }
})
