


































import Vue from "vue"

import NucleoGERemove from "@/components/svg/NucleoGERemove.vue"

export default Vue.extend({
  components: {
    NucleoGERemove
  },

  props: {
    contentMinWidth: {
      type: String, // CSS min-width
      default: undefined
    },
    rightSide: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    contentStyles(): Record<string, string> {
      return {
        minWidth: this.contentMinWidth
      }
    }
  },

  methods: {
    /** @param {Object} e - DOM event */
    onClick(e: Event) {
      const close = this.$refs.close as Vue | undefined
      if (!close) {
        return // not rendered anymore
      }

      const content = this.$refs.content as HTMLElement | undefined
      const t = e.target as Element
      if (!content?.contains(t) || close.$el.contains(t)) {
        this.$emit("hide")
      }
    }
  }
})
