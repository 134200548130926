






















































import Vue from "vue"

import BaseErrorText from "@/components/BaseErrorText.vue"
import BaseLoading from "@/components/BaseLoading.vue"
import LunchMenu from "@/components/LunchMenu.vue"
import LunchSectionMenuExplanation from "@/components/LunchSectionMenuExplanation.vue"

export default Vue.extend({
  components: {
    BaseErrorText,
    BaseLoading,
    LunchMenu,
    LunchSectionMenuExplanation
  }
})
