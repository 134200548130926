import App from "@/App.vue"
import ApolloClient from "apollo-boost"
import router from "@/router"
import store from "@/store"
import Vue from "vue"
import VueApollo from "vue-apollo"
import VueFormulate from "@braid/vue-formulate"
import { de as vueFormulateLocaleDE } from "@braid/vue-formulate-i18n"

Vue.config.productionTip = false

// Setup apollo.
Vue.use(VueApollo)
const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_GRAPHQL_URL // should be absolute
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

// Setup vue-formulate.
Vue.use(VueFormulate, {
  plugins: [vueFormulateLocaleDE],
  locale: "de"
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount("#app")
