export function replaceNewlines(s: string, replacement = ""): string {
  return s.replace(/\r?\n|\r/g, replacement)
}

/** @returns string[] trimmed and without empty string elements */
export function splitLines(s: string): string[] {
  let lines = s.split(/\r?\n/)
  lines = lines.map(s => s.trim())
  return lines.filter(s => s.length > 0)
}
