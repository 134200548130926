














import Vue, { PropType } from "vue"

import { LunchDish } from "@/model/LunchDish"

export default Vue.extend({
  props: {
    dish: {
      type: Object as PropType<LunchDish>,
      required: true
    }
  }
})
