








import Vue from "vue"

import AppFooter from "@/components/AppFooter.vue"
import AppNav from "@/components/AppNav.vue"

export default Vue.extend({
  components: {
    AppFooter,
    AppNav
  }
})
