











































import Vue, { PropType } from "vue"

import BaseErrorText from "@/components/BaseErrorText.vue"
import BaseLoading from "@/components/BaseLoading.vue"
import { Link } from "@/model/Link"
import { splitLines } from "@/util/string"
import SectionBigHeader from "@/components/SectionBigHeader.vue"

/** Received from CMS. */
interface RawContent {
  header: string
  text: string
}

/** Used in component. */
interface Content {
  header: string
  text: string[]
}

interface QueryResult {
  loading: boolean
  data: {
    /* eslint-disable camelcase */
    big_header_sections_by_id: RawContent
    /* eslint-enable camelcase */
  }
  error: Error
}

export default Vue.extend({
  components: {
    BaseErrorText,
    BaseLoading,
    SectionBigHeader
  },

  props: {
    sectionId: {
      type: String,
      required: true
    },
    links: {
      type: Array as PropType<Link[]>,
      default: () => []
    }
  },

  data() {
    // keep in sync with methods.resetData
    return {
      content: undefined as Content | undefined
    }
  },

  computed: {
    queryVariables(): Record<string, string> {
      return {
        id: this.sectionId
      }
    }
  },

  methods: {
    onQueryResult({ loading, data, error }: QueryResult): void {
      if (loading) {
        return // handled in template
      }

      if (error || !data) {
        this.resetData()
        return // handled in template
      }

      const rawcontent = data.big_header_sections_by_id
      this.content = {
        header: rawcontent.header,
        text: splitLines(rawcontent.text)
      }
    },

    resetData(): void {
      this.content = undefined
    }
  }
})
