






























import Vue from "vue"

import AppNavMobileSidebar from "@/components/AppNavMobileSidebar.vue"
import NucleoGHamburger from "@/components/svg/NucleoGHamburger.vue"

export default Vue.extend({
  components: {
    AppNavMobileSidebar,
    NucleoGHamburger
  },

  data() {
    return {
      showSidebar: false
    }
  }
})
