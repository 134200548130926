








import Vue from "vue"

export default Vue.extend({
  props: {
    price: {
      type: Number,
      required: true
    },
    /* hide root template element if price is zero */
    hideZero: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    priceDisplay(): string {
      if (Number.isInteger(this.price)) {
        return this.price.toFixed(0)
      } else {
        return this.price.toFixed(2)
      }
    },

    hideRootElement(): boolean {
      return this.hideZero && this.price === 0
    }
  }
})
