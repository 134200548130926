











import Vue from "vue"

import AppNavDesktop from "@/components/AppNavDesktop.vue"
import AppNavMobile from "@/components/AppNavMobile.vue"
import { getMeta } from "@/router"

export default Vue.extend({
  components: {
    AppNavDesktop,
    AppNavMobile
  },

  computed: {
    hideNavDesktop(): boolean {
      return getMeta(this.$route, "hideAppNavDesktop") || false
    }
  }
})
