












import Vue from "vue"

import BasePrice from "@/components/BasePrice.vue"

export default Vue.extend({
  components: {
    BasePrice
  },

  props: {
    price: {
      type: Number,
      required: true
    }
  }
})
