






















import Vue, { PropType } from "vue"

import {
  Link,
  isRouterLink
} from "@/model/Link"

export default Vue.extend({
  props: {
    link: {
      type: Object as PropType<Link>,
      required: true
    },
    textDecoration: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isRouterLink(): boolean {
      return isRouterLink(this.link)
    },

    classObject(): Record<string, string | boolean | undefined> {
      return {
        "link--no-text-decoration": !this.textDecoration
      }
    }
  }
})
