















import Vue, { PropType } from "vue"

export default Vue.extend({
  props: {
    padding: {
      type: String as PropType<string | undefined>,
      default: undefined
    }
  },

  computed: {
    styleObject(): Record<string, string | undefined> {
      return {
        padding: this.padding
      }
    }
  }
})
