import { Location } from "vue-router"

import {
  email,
  telephone,
  telephoneDisplay
} from "@/model/constants"

export interface Link {
  text: string
  to: Location | string
}

interface RouterLink extends Link {
  to: Location
}

export function isRouterLink(x: Link | RouterLink): x is RouterLink {
  return typeof x.to === "object"
}

export function newEmailLink(text = email): Link {
  return {
    text,
    to: `mailto:${email}`
  }
}

export function newTelephoneLink(text = telephoneDisplay): Link {
  return {
    text,
    to: `tel:${telephone}`
  }
}

export function newSiteCredits(text = "m-zielke.de"): Link {
  return {
    text,
    to: "https://m-zielke.de"
  }
}

export const navLinks: RouterLink[] = [
  {
    text: "Mittagstisch",
    to: {
      name: "Home",
      hash: "#anchor-lunch"
    }
  },
  {
    text: "Speisekarte",
    to: { name: "Speisekarte" }
  },
  {
    text: "Küche",
    to: { name: "Kueche" }
  },
  {
    text: "Location",
    to: { name: "Location" }
  },
  {
    text: "Kontakt",
    to: { name: "Kontakt" }
  },
  {
    text: "Reservierung",
    to: { name: "Reservierung" }
  }
]

export const legalLinks: RouterLink[] = [
  {
    text: "Impressum",
    to: { name: "Impressum" }
  },
  {
    text: "Datenschutz",
    to: { name: "Datenschtuz" }
  }
]
