





























import Vue from "vue"

import {
  Link,
  navLinks
} from "@/model/Link"
import LinkListColumn from "@/components/LinkListColumn.vue"
import LogoPadded from "@/components/LogoPadded.vue"

export default Vue.extend({
  components: {
    LinkListColumn,
    LogoPadded
  },

  data() {
    return {
      linkFontSize: "1.25em"
    }
  },

  computed: {
    links(): {left: Link[], right: Link[]} {
      // Use ceil to have more links on left for uneven navLins.length.
      const cutoff = Math.ceil(navLinks.length / 2)
      return {
        left: navLinks.slice(0, cutoff),
        right: navLinks.slice(cutoff)
      }
    }
  }
})
