import Vue from "vue"
import VueRouter, {
  Route,
  RouteConfig,
  RouterOptions
} from "vue-router"
import Home from "@/views/Home.vue"

Vue.use(VueRouter)

// TODO set title and meta.description dynamically.

interface Meta {
  hideAppNavDesktop?: boolean
}

export function getMeta<K extends keyof Meta>(r: Route, key: K): Meta[K] {
  const m = r.meta ?? {} as Meta
  return m[key]
}

const routes: Array<RouteConfig> = [
  {
    name: "Datenschutz",
    path: "/datenschutz",
    component: () => import(/* webpackChunkName: "Datenschutz" */ "@/views/Datenschutz.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: {
      hideAppNavDesktop: true
    },
    component: Home
  },
  {
    name: "Impressum",
    path: "/impressum",
    component: () => import(/* webpackChunkName: "Impressum" */ "@/views/Impressum.vue")
  },
  {
    name: "Kontakt",
    path: "/kontakt",
    component: () => import(/* webpackChunkName: "Kontakt" */ "@/views/Kontakt.vue")
  },
  {
    name: "Kueche",
    path: "/kueche",
    component: () => import(/* webpackChunkName: "Kueche" */ "@/views/Kueche.vue")
  },
  {
    name: "Location",
    path: "/location",
    component: () => import(/* webpackChunkName: "Location" */ "@/views/Location.vue")
  },
  {
    name: "Reservierung",
    path: "/reservierung",
    component: () => import(/* webpackChunkName: "Reservierung" */ "@/views/Reservierung.vue")
  },
  {
    name: "Speisekarte",
    path: "/speisekarte",
    component: () => import(/* webpackChunkName: "Speisekarte" */ "@/views/Speisekarte.vue")
  }
]

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
const scrollBehavior: RouterOptions["scrollBehavior"] = (to, from, savedPosition) => {
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: "smooth"
    }
  }
  if (savedPosition) {
    return savedPosition
  }
  return { x: 0, y: 0 }
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router
